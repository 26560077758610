.search {
    width: 50%;
    margin-right: 33%;
    margin-left: 33%;
    border-radius: 0;
    
}
.search .results {
    width: 100% !important;
}
